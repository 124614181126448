//Underline tabs
.nav{
    &.nav-tabs-underline{
        border-bottom: 2px solid $gray-300;
        .nav-link{
            position:relative;
            padding-left: 0;
            padding-right: 0;
            margin-bottom: -2px;
            font-weight: $font-weight-semibold;
            &::before{
                content: "";
                width:0;
                height:2px;
            background-color: $primary;
            position: absolute;
            bottom:0;
            left:0;
            transition: width .3s, color .15s;
            }
            &.active,&.show{
                color: $primary;
                &::before{
                    width:100%;
                }
            }
            &:not(:first-child){
                margin-left: 1rem;
            }
            @include media-breakpoint-up(lg){
                &:not(:first-child){
                    margin-left: 1.75rem;
                }
            }
        }
    }
}


//Inline Tabs
.tab-inline-pane {
    display: none;
    &.active{
        display: block;
    }
    &.transform-in{
    opacity: 0;
    visibility: hidden;
    transition:opacity .2s linear;
    &.active{
        opacity: 1;
        visibility: visible;
    }
    }
  }
  .tab-inline-link{
      display: inline-block;
      line-height: 1;
      padding: 0.1rem 0.2rem;
      vertical-align: middle;
      background-color: tint-color($danger,95%);
      &.active{
          background-color: tint-color($danger,70%);
      }
  }