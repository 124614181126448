.hover-lift {
    transition: all 0.35s;
    &:hover {
        transform: translate3d(0, -5px, 0);
    }
}

.img-zoom {
    transition: transform 1.8s cubic-bezier(0.2, 1, 0.2, 1), -webkit-transform 1.8s cubic-bezier(0.2, 1, 0.2, 1);
}
.card-over {
    position: relative;
    overflow: hidden;
    display: block;
}
.card-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: stretch;
    align-items: flex-end;
    background-image: linear-gradient(to bottom, transparent 27%, rgba($dark, 0.85));
    &.overlay-center {
        background-image: linear-gradient(-135deg, rgba($primary, 1), rgba($pink, 0.85));
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .transition-loop {
        color: currentColor;
        width: 100%;
        margin-bottom: 0;
        position: relative;
    }
}
.card-hover {
    .card-overlay {
        opacity: 0;
        transition: all 0.25s;
        .transition-loop {
            position: relative;
            z-index:1;
            li {
                transition: all 0.35s ease-in-out;
                opacity: 0;
                transform: translate3d(0, 40px, 0);
            }
        }
    }
    &:hover {
        .img-zoom {
            -webkit-transform: scale(1.05);
            transform: scale(1.05);
        }
        .card-overlay {
            opacity: 1;
        }
        .transition-loop {
            li {
                opacity: 1;
                transform: none;
                @for $i from 1 through 21 {
                    &:nth-child(#{$i}) {
                      transition-delay: $i * 100ms;
                    }
                  }
            }
        }
    }
}

//card text splitting on hover
.card-split-hover {
    .card-overlay {
        opacity: 0;
        transition: all 0.15s;
        // .splitting {
        //     overflow: hidden;
        //     .char {
        //         opacity: 0;
        //         transition: transform 0.3s cubic-bezier(0.3, 0, 0.3, 1), opacity 0.3s linear;
        //         transition-delay: calc(0ms + (10ms * var(--char-index)));
        //     }
        //     .char{
        //         transform: translateY(100%);
        //     }
        // }
    }
    &:hover{
        .card-overlay {
            opacity: 1;
            // .char{
            //     opacity: 1;
            // transform: translateY(0);
            // transition-delay: calc(0ms + (50ms * var(--char-index)));
            // }
        }
    }
}

.reveal__image {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    .revealImage__item {
        position: relative;
    }
}



.hover-reveal {
    position: absolute;
    z-index: -1;
    width: 220px;
    height: 300px;
    top: 0;
    left: 0;
    pointer-events: none;
    opacity: 0;
    .hover-reveal__inner {
        overflow: hidden;
    }
    .hover-reveal__inner,
.hover-reveal__img {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
}
    .hover-reveal__img {
        max-width: 100%;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
    }
}





//hover pulse
.hover-pulse{
    animation:pulse 1.5s infinite forwards;
}
.infinite{
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
}


