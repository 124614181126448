

  .card-price{
    position:relative;
    overflow:hidden;
    sup{
        font-size:$font-size-sm;
      }
      small,.small{
        font-size:1rem;
        font-weight:700;
        color:$text-muted;
      }
  }
  .price-yearly,.price-monthly{
    padding:.25rem;
      overflow:hidden;
    transition:transform .25s  .15s, opacity .2s linear .1s;
  }
  .price-yearly{
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    opacity:0;
    transform:translateY(100%);
  }

  
.plans-container.yearly{
    .price-yearly{
     opacity:1;
      transform:none;
    }
     .price-monthly{
     opacity:0;
      transform:translateY(-100%);
    }
  }