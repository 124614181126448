.dropdown-item {
    font-family: $dropdown-font-family;
    transition: $transition-base;
    text-transform: $dropdown-text-transform;
    letter-spacing: $dropdown-letter-spacing;
    font-size: $dropdown-font-size;
    font-weight: $dropdown-font-weight;
    &.dropdown-toggle {
        &::after {
            content: "";
            position: relative;
            display: inline-block;
            vertical-align: middle;
            width: 4px;
            height: 4px;
            border-radius: 100%;
            background-color: currentColor;
            margin-left: 0.5rem;
            opacity: 0.25;
        }
    }
}
.navbar .dropdown-menu{
    border-radius: 0px;
}
.dropdown-menu {
    .dropdown-header {
        text-transform: $dropdown-text-transform;
    }
}
.dropdown-toggle {
    > .dropdown-arrow {
        width: 13px;
        height: 9px;
        margin-left: 0.15rem;
        display: inline-block;
        vertical-align: middle;
        transition: transform 0.5s ease;
        opacity: 0.5;
    }
    &.show > .dropdown-arrow {
        transform: rotateX(180deg);
    }
}
.dropup {
    .dropdown-toggle > .dropdown-arrow {
        transform: rotateX(180deg);
    }
}
.dropstart {
    .dropdown-toggle > .dropdown-arrow {
        transform: rotateX(90deg);
    }
}
.dropend {
    .dropdown-toggle > .dropdown-arrow {
        transform: rotateX(-90deg);
    }
}
//Dropdown-menu no collapse items in navbar
.navbar-no-collapse-items {
    .dropdown-menu.dropdown-menu-xs {
        min-width: 300px;
        width: 300px;
    }
}
//Dropdown Mega Menu
@include media-breakpoint-up(lg) {
    .dropdown-menu-end {
        right:0;
        .dropdown-item.dropdown-toggle {
            &::after {
                display: none;
            }
            &::before {
                margin-left: 0;
                margin-right: 0.5rem;
                content: "";
                width: 4px;
                height: 4px;
                border-radius: 100%;
                display: inline-block;
                vertical-align: middle;
                background-color: currentColor;
                opacity: 0.25;
            }
        }
    }
    .navbar .dropdown-menu {
        display: block;
        opacity: 0;
        visibility: hidden;
        transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
        transform-origin: 50% -50px;
        transform: perspective(100px) rotateX(-15deg);
        &.show {
            -webkit-transform: none;
            transform: none;
            opacity: 1;
            visibility: visible;
        }
    }
    .dropdown-menu-fw {
        width: 100%;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }
    .dropdown-menu-md {
        width: 720px;
    }
    .dropdown-menu-sm {
        width: 540px;
    }
    .navbar-no-collapse-items {
        .dropdown-menu.dropdown-menu-xs {
            min-width: 360px;
            width: 360px;
        }
    }
    .dropdown-menu-xs {
        width: 280px;
    }
}
@include media-breakpoint-up(xl) {
    .navbar {
        .dropdown-menu-lg {
            width: 930px;
        }
        .dropdown-menu-md {
            width: 720px;
        }
        .dropdown-menu-sm {
            width: 540px;
        }
        .dropdown-menu-xs {
            width: 340px;
        }
    }
}

@include media-breakpoint-down(lg) {
    .navbar.navbar-expand-lg {
        .dropdown-menu {
            box-shadow: none;
            border-left: 1px solid $border-color;
            padding-left:0;
            padding-top: 0;
            padding-bottom: 0;
            .dropdown-item.dropdown-toggle {
                pointer-events: none;
                padding-left: 0;
                opacity: .6;
                font-size: $font-size-sm;
            }
        }
        .dropend,
        .dropstart {
            padding-left: 1rem;
            .dropdown-menu {
                opacity: 1;
                display: block;
                position: static;
                padding-left:0;
                padding-top: 0;
                padding-bottom: 0;
            }
        }
        .nav-item.dropdown {
            position: static;
        }
        .navbar-no-collapse-items {
            .dropdown-menu {
                min-width: 100%;
                max-width: 100%;
                width: 100%;
            }
        }
    }
}

.dropdown-menu.dropdown-menu-xxs {
    max-width: 160px;
    min-width: 160px;
}
