//Breadcrumb

.breadcrumb{
    .breadcrumb-item{
       + .breadcrumb-item::before{
        font-family: bootstrap-icons!important;
        font-weight: normal!important;
        font-size: $font-size-sm * .9375;
        content: $breadcrumb-divider;
         }
    }
}
.text-white,.text-light{
    .breadcrumb-item {
        &.active{
            color: rgba($white,.54);
        }
        + .breadcrumb-item::before{
            color: rgba($white,.35);
        }
    }
}