// Buttons style
.btn {
  text-transform: $btn-text-transform;
  letter-spacing: $btn-letter-spacing;
  font-weight: $btn-font-weight;
  transition: all 0.25s;
}

//Button hover-arrow
.btn-hover-arrow {
  position: relative;
  span {
    left: 0px;
    vertical-align: middle;
    display: inline-block;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    transition: opacity 0.45s cubic-bezier(0.25, 1, 0.33, 1), transform 0.45s cubic-bezier(0.25, 1, 0.33, 1);
    -webkit-transition: opacity 0.45s cubic-bezier(0.25, 1, 0.33, 1),
      -webkit-transform 0.45s cubic-bezier(0.25, 1, 0.33, 1);
    position: relative;
  }
  .bi {
    text-align: center;
    vertical-align: middle;
    display: inline-block;
    width: 24px;
    height: 24px;
    font-size: 24px;
    line-height: 1;
    position: relative;
    margin-left: -24px;
    opacity: 0;
    transition: all 0.45s cubic-bezier(0.25, 1, 0.33, 1);
    -webkit-transition: all 0.45s cubic-bezier(0.25, 1, 0.33, 1);
  }
  &:hover {
    span {
      -webkit-transform: translateX(-18px);
      transform: translateX(-18px);
    }
    .bi {
      opacity: 1 !important;
      -ms-transform: translateX(6px);
      -webkit-transform: translateX(6px);
      transform: translateX(6px) !important;
    }
  }
}

//
// Alternate buttons
//

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

//Buttons rise
.btn.btn-rise {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  &[class^="btn-outline-"],
  &[class*="btn-outline-"] {
    border: 0px;
    .btn-rise-border {
      border: solid;
      border-width: $btn-border-width;
      border-color: currentColor;
    }
  }
  .btn-rise-text {
    color: currentColor;
    transition: color 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    will-change: color;
    position: relative;
  }
  .btn-rise-bg {
    opacity: 0;
    transform: scale(0.6);
    transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
    pointer-events: none;
    will-change: opacity, transform;
  }

  .btn-rise-border {
    transition: transform 0.4s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    pointer-events: none;
    will-change: transform;
  }
  &:hover {
    box-shadow: none;
    &[class^="btn-outline-"],
    &[class*="btn-outline-"] {
      background-color: transparent;
      .btn-rise-border {
        opacity: 0;
        transform: scale(1.2, 1.44);
        transition: transform 0.4s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.3s cubic-bezier(0.19, 1, 0.22, 1);
      }
      .btn-rise-bg {
        opacity: 1;
        transform: scale(1);
        transition: transform 0.9s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
      }
    }
  }
}
.btn-rise-bg,
.btn-rise-border {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
}
.btn {
  .btn-rise-border,.btn-rise-bg{
    border-radius: $btn-border-radius;
  }
  &.btn-lg{
    .btn-rise-border,.btn-rise-bg{
      border-radius: $border-radius-lg;
    }
  }
  &.btn-sm{
    .btn-rise-border,.btn-rise-bg{
      border-radius: $border-radius-sm;
    }
  }
  &.rounded-pill{
    .btn-rise-border,.btn-rise-bg{
      border-radius: $border-radius-pill;
    }
  }
  &.rounded-circle{
    .btn-rise-border,.btn-rise-bg{
      border-radius: 100%;
    }
  }
  &.rounded-blob{
    .btn-rise-border,.btn-rise-bg{
      border-radius: $border-radius-blob;
    }
  }
}
