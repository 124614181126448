.link-underline{
    display: inline-block;
    position: relative;
    padding-bottom: .25rem;
    overflow: hidden;
    transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    vertical-align: middle;
    transition: color .25s cubic-bezier(.28,.44,.49,1);
    &::after{
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
        width: 100%;
        will-change: transform;
        background-color: currentColor;
        opacity: .35;
    }
    &:hover{
        &::after{
            opacity: 1;
                 animation: scaleOut .25s cubic-bezier(.28,.44,.49,1) 0s forwards,scaleIn .25s cubic-bezier(.28,.44,.49,1) .25s forwards;
    }
    }
    }


    //Link hover underline
    
    .link-hover-underline{
        position: relative;
        display: inline-block;
        &::after{
            content: "";
            display: block;
            position: absolute;
            height: 1px;
            top: 100%;
            left: .05em;
            right: .05em;
            background: currentColor;
            -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
            -webkit-transform: scaleX(0);
            transform: scaleX(0);
            -webkit-transition: -webkit-transform .6s cubic-bezier(1,0,0,1);
            transition: -webkit-transform .6s cubic-bezier(1,0,0,1);
            transition: transform .6s cubic-bezier(1,0,0,1);
            transition: transform .6s cubic-bezier(1,0,0,1),-webkit-transform .6s cubic-bezier(1,0,0,1);
        }
        &:hover{
        &::after{
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
            -webkit-transition-duration: .9s;
            transition-duration: .9s;
            -webkit-transition-timing-function: cubic-bezier(.19,1,.22,1);
            transition-timing-function: cubic-bezier(.19,1,.22,1);
            -webkit-transform-origin: 0 50%;
            transform-origin: 0 50%;
        }
        }
        }
        .link-decoration:not(:hover):not(.active){
            text-decoration: underline;
        }
      
.link-hover-move-arrow{
    position: relative;
    display: inline-block;
    transition: padding .35s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    svg{
        width:10px;
        height:auto;
        margin-right: 2px;
        >path{
            fill: currentColor;
        }
    }
    &:hover{
        padding-left: .5rem;
    }
}

        //Link hover image reavel
        /* Recommended styles for Splitting */
.splitting .word,
.splitting .char {
	display: inline-block;
}

/* Psuedo-element chars */
.splitting .char {
	white-space: nowrap;
	position: relative;
}

        .gallery {
            display: grid;
            grid-template-columns: repeat(3,minmax(min-content,320px));
            grid-column-gap: 2rem;
            grid-row-gap: 5vh;
            padding: 4rem 0 20rem;
        }
        
        .gallery__item {
            margin: 0;
            display: grid;
            grid-template-areas: 'gallery-caption' 'gallery-image';
            grid-template-columns: 100%;
        }
        
        .gallery__item-img {
            grid-area: gallery-image;
            width: 100%;
            overflow: hidden;
            position: relative;
            will-change: transform, opacity;
            transform: translateZ(0);
        }
        
        .js .gallery__item-img {
            opacity: 0;
        }
        
        .gallery__item-imginner {
            background-size: cover;
            background-position: 50% 0;
            width: 100%;
            padding-bottom: 140%;
            will-change: transform;
        }
        
        .gallery__item-caption {
            grid-area: gallery-caption;
        }
        
        .gallery__item-title {
            font-size: 0.9rem;
            margin: 1rem 0 0.5rem;
            font-weight: 500;
            display: inline-block;
        }
        
        .gallery__item-title span.char {
            opacity: 0;
            will-change: transform;
        }
        