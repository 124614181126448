.modal-header{
    .btn-close{
        padding:0;
        right:0;
        top:0;
        margin:0;
    }
}
.btn-close{
    width:32px;
    height:32px;
    background-image:none;
    opacity:1;
}

//sidebar modal
.modal-sidebar{
    .modal-dialog{
      backface-visibility:hidden;
      margin:0;
      width:290px;
      transition: transform 500ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
      .btn-close{
        position:relative;
        padding:0;
        display:flex;
        align-items:center;
        justify-content:center;
        border:1px solid;
        border-radius:100px;
      }
      .modal-content{
      padding:1rem;
        margin:0;
        border:0;
        border-radius:0;
        height:100%;
        width:100%;
        overflow-y: auto;
        overflow-x: hidden;
        color:$headings-color;
        .modal-footer,.modal-header{
          padding:1rem 0rem;
        }
        .modal-body{
          height:100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding:1rem 0;
        }
        .nav-link{
          display:flex;
          align-items:center;
          color:currentColor;
        }
      }
    }
    &.modal.fade{
      .modal-dialog{
        &.end-0{
        transform:translateX(100%);
      }
      &.start-0{
        transform:translateX(-100%);
      }
      }
      &.show{
    backdrop-filter:blur(4px);
        .modal-dialog{
          &.end-0{
            transform:translateX(0%);
            .modal-content{
              .nav-item{
          animation:transform-x .6s both;
               @for $i from 0 through 20 {
      &:nth-child(#{$i + 1}) {
        animation-delay: 0.05s * $i;
      }
    }
              }
            }
          }
        }
      }
    }
  }
  @media(min-width:768px){
    .modal-sidebar{
      .modal-dialog{
        width:360px;
        .modal-content{
          padding:1.5rem 2rem;
        }
      }
    }
  }
  