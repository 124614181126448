//Navs
.nav{
    .nav-link{
        color: $headings-color;
    }
}
.nav{
    &.flex-column{
    .nav-link{
        padding-left: 0;
    }
    }
}