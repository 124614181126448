//form styles
.form-control.text-white,input.text-white,select.text-white,textarea.text-white{
    &::placeholder{
        color: rgba($white,.65);
    }
    &::-webkit-placeholder{
        color: rgba($white,.65);
    }
    &::-ms-placeholder{
        color: rgba($white,.65);
    }
    &::-moz-placeholder{
        color: rgba($white,.65);
    }
}



//Form input icons
.input-icon-group{
    position:relative;
    .form-control{
      padding-left:3rem;
    }
    .form-control-lg{
      padding-left:3.5rem;
    }
      .form-control-sm{
      padding-left:2.25rem;
    }
    .input-icon{
      position:absolute;
      left:0;
      top:0;
      height:100%;
      display:flex;
      align-items:center;
      justify-content:center;
      width:2.5rem;
      &::after{
        content:"";
        position:absolute;
        right:0;
        top:0;
        width:1px;
        height:100%;
        background-image:linear-gradient(to top, rgba(#fff,0) 0%, rgba(#e9e9e9,.5)50%,rgba(#fff,0)100%)
      }
      >svg,i{
        height:1.125rem;
        width:1.125rem;
        opacity:.3;
      }
    }
    &.input-icon-group-sm{
      .input-icon{
        width:2rem;
         >svg,i{
           height:1rem;
        width:1rem;
        }
      }
    }
     &.input-icon-group-lg{
      .input-icon{
        width:3rem;
         >svg,i{
           height:1.5rem;
        width:1.5rem;
        }
      }
    }
  }



  //Form switch
