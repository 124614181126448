//Quill editor

.ql-editor{
    min-height:9rem;
    font-family:inherit;
  }
  .ql-toolbar,.ql-container{
    &.ql-snow{
    border:0px;
    }
  }
  .ql-container{ 
    border-bottom-left-radius:.5rem;
     border-bottom-right-radius:.5rem;
    box-shadow:0px 4px 11px rgba($dark,.075);
  }
  .ql-toolbar.ql-snow{
    background-color:$light;
    border-top-left-radius:.5rem;
     border-top-right-radius:.5rem;
    .ql-formats{
      button{
         .ql-stroke{
        stroke:$body-color;
        transition:All .3s ease;
      }
        .ql-fill{
        fill:$body-color;
        transition:All .3s ease;
      }
        &:hover{
            .ql-stroke{
        stroke:$primary;
      }
        .ql-fill{
        fill:$primary;
      }
        }
      }
    }
  }