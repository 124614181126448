

.showMore-link{
    display:inline-block;
    &[aria-expanded="false"]{
       &::after{
        content:"show more...";
      }
    }
    &[aria-expanded="true"]{
      &::after{
        content:"show less...";
      }
    }
  }