// border and shadows styles

//border radius-blob
.rounded-blob{
    border-radius: $border-radius-blob;
}
.rounded-xl{
  border-radius: $border-radius-xl;
}
.rounded-block{
  border-radius: $border-radius-block;
}
//Rounded block
.rounded-top-start-block{
  border-top-left-radius: $border-radius-block;
}
.rounded-top-end-block{
  border-top-right-radius: $border-radius-block;
}
.rounded-bottom-start-block{
  border-bottom-left-radius: $border-radius-block;
}
.rounded-bottom-end-block{
  border-bottom-right-radius: $border-radius-block;
}
.rounded-start-block{
  border-bottom-left-radius: $border-radius-block;
  border-top-left-radius: $border-radius-block;
}
.rounded-end-block{
  border-bottom-right-radius: $border-radius-block;
  border-top-right-radius: $border-radius-block;
}
.rounded-top-block{
  border-top-right-radius: $border-radius-block;
  border-top-left-radius: $border-radius-block;
}
.rounded-bottom-block{
  border-bottom-right-radius: $border-radius-block;
  border-bottom-left-radius: $border-radius-block;
}
//Rounded Xl
.rounded-top-start-xl{
  border-top-left-radius: $border-radius-xl;
}
.rounded-top-end-xl{
  border-top-right-radius: $border-radius-xl;
}
.rounded-bottom-start-xl{
  border-bottom-left-radius: $border-radius-xl;
}
.rounded-bottom-end-xl{
  border-bottom-right-radius: $border-radius-xl;
}
.rounded-start-xl{
  border-bottom-left-radius: $border-radius-xl;
  border-top-left-radius: $border-radius-xl;
}
.rounded-end-xl{
  border-bottom-right-radius: $border-radius-xl;
  border-top-right-radius: $border-radius-xl;
}
.rounded-top-xl{
  border-top-right-radius: $border-radius-xl;
  border-top-left-radius: $border-radius-xl;
}
.rounded-bottom-xl{
  border-bottom-right-radius: $border-radius-xl;
  border-bottom-left-radius: $border-radius-xl;
}

//rounded-pill
.rounded-top-start-pill{
  border-top-left-radius: $border-radius-pill;
}
.rounded-top-end-pill{
  border-top-right-radius: $border-radius-pill;
}
.rounded-bottom-start-pill{
  border-bottom-left-radius: $border-radius-pill;
}
.rounded-bottom-end-pill{
  border-bottom-right-radius: $border-radius-pill;
}


//Border dashed
.border-dashed{
  border: 1px dashed rgba($border-color,1);
}
.border-dashed-bottom{
  border-bottom: 1px dashed rgba($border-color,1);
}
.border-dashed-start{
  border-left: 1px dashed rgba($border-color,1);
}
.border-dashed-end{
  border-right: 1px dashed rgba($border-color,1);
}
  @include media-breakpoint-up(lg){
      .border-start-lg{
          border-left: 1px solid $border-color;
      }
      .border-end-lg{
        border-right: 1px solid $border-color;
    }
    .border-top-lg{
        border-top: 1px solid $border-color;
    }
    .border-bottom-lg{
        border-top: 1px solid $border-color;
    }
    .border-0-lg{
        border: 0px;
    }
  }
  
  @include media-breakpoint-up(md){
    .border-start-md{
        border-left: 1px solid $border-color;
    }
    .border-end-md{
      border-right: 1px solid $border-color;
  }
  .border-top-md{
      border-top: 1px solid $border-color;
  }
  .border-bottom-md{
      border-top: 1px solid $border-color;
  }
  .border-0-md{
    border: 0px;
}
}


//border-radius
.rounded-bottom{
    border-top-left-radius: 0!important;
    border-top-right-radius: 0!important;
}
.rounded-top{
    border-bottom-left-radius: 0!important;
    border-bottom-right-radius: 0!important;
}

.rounded-start-0{
  border-top-left-radius: 0!important;
  border-bottom-left-radius: 0!important;
}
.rounded-top-satrt-0{
  border-top-left-radius: 0!important;
}
.rounded-bottom-start-0{
  border-bottom-left-radius: 0!important;
}
.rounded-top-end-0{
  border-top-right-radius: 0!important;
}
.rounded-bottom-end-0{
  border-bottom-right-radius: 0!important;
}
.rounded-end-0{
  border-top-right-radius: 0!important;
  border-bottom-right-radius: 0!important;
}
.rounded-top-0{
  border-top-left-radius: 0!important;
  border-top-right-radius: 0!important;
}
.rounded-bottom-0{
  border-bottom-left-radius: 0!important;
  border-bottom-right-radius: 0!important;
}
//Shadows on hover
  // Hover shadows
  [class*="hover-shadow"]{
    transition: $transition-base;
      }
      .hover-shadow{
        box-shadow: 0 0 0 transparent;
        &:hover{
          box-shadow: $box-shadow!important;
        }
      }
      .hover-shadow-lg{
        box-shadow: 0 0 0 transparent;
        &:hover{
          box-shadow: $box-shadow-lg!important;
        }
      }
      
      .hover-shadow-sm{
        box-shadow: 0 0 0 transparent;
        &:hover{
          box-shadow: $box-shadow-sm;
        }
      }



      //grid separator
      .grid-separator{
        overflow:hidden;
        >*{
          box-shadow: -1px -1px 0px 0px rgba($border-color,1);
      }
        >[class*="col-"],[class^="col-"]{
          padding:2rem 1rem;
        }
      }
      
      .text-white{
        .grid-separator{
          >*{
            box-shadow:-1px -1px 0 0 rgba($white,.125);
          }
        }
      }




      //Border-colors on dark bg
      .text-white,.text-light{
        .border,[class*="border-"]{
          border-color: rgba($white,.125)!important;
        }
      }