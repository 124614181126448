//sizing
@for $i from 1 through 10 {
  $vheight: ($i * 10);
  .min-vh-#{$vheight} {
    min-height: #{$vheight}vh!important;
  }
}

@for $i from 1 through 10 {
  $vh: ($i * 10);
  .vh-#{$vh} {
    height: #{$vh}vh!important;
  }
}
@for $i from 1 through 10 {
  $vw: ($i * 10);
  .vw-#{$vw} {
    width: #{$vw}vw;
  }
}
    //height in pixels - for small thumbnail images or use for icons
    @for $i from 1 through 70 {
        $height:($i * 10);
        &.height-#{$i*10}{
            height:#{$height}px;
        }
      }
      @for $i from 1 through 70 {
        $width:($i * 5);
        &.width-#{$i*5}{
            width:#{$width}px;
        }
      }
      @for $i from 1 through 70 {
        $size:($i * 5);
        &.size-#{$i*5}{
            width:#{$size}px;
            height:#{$size}px;
        }
      }





      //sizing for specific device size
      @include media-breakpoint-up(md){
        @for $i from 1 through 20 {
          $width:($i * 5);
          .w-md-#{$width} {
            width : #{$width}#{"%"}!important;
          }
        }
        @for $i from 1 through 20 {
          $height:($i * 5);
          .h-md-#{$height} {
            height : #{$height}#{"%"}!important;
          }
        }
      }
      @include media-breakpoint-up(lg){
        @for $i from 1 through 20 {
          $width:($i * 5);
          .w-lg-#{$width} {
            width : #{$width}#{"%"}!important;
          }
        }
        @for $i from 1 through 20 {
          $height:($i * 5);
          .h-lg-#{$height} {
            height : #{$height}#{"%"}!important;
          }
        }
      }



.min-height-100{
  min-height: 100%;
}