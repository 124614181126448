//Scroll back to top
.toTop {
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 1rem;
  margin-bottom: 1rem;
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  background-color: $secondary;
  color: $white;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  line-height: 1;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-30px);
  transition: 0.4s cubic-bezier(0.33, 1, 0.68, 1);
  z-index: 9999;
  &.show {
    visibility: visible;
    opacity: 1;
    transform: none;
  }
}

//scroll down vertical line
.scrollBtn {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  > a {
    padding-bottom: 50px;
    display: block;
    position: relative;
    &::before {
      width: 2px;
      height: 25px;
      position: absolute;
      left: 50%;
      bottom: 25px;
      background: currentColor;
      content: "";
      transform: translateX(-50%);
      animation: vertical 2s linear infinite;
    }
  }
}


//scroll-margin-top:
@include media-breakpoint-up(lg){
  .scroll-space{
    scroll-margin-top: 76px;
  }
}

.scroll-space-secondary{
  scroll-margin-top: 57px;
}



//scrollout splitting text- animation
