//Backgrounds



//BG pattern dots

.bg-pattern{
    background-image: radial-gradient(circle, currentColor 1px, rgba(0,0,0,0) 1px);
    background-size: 1rem 1rem;
}




//Opacity
.opacity-0 {
    opacity: 0 !important;
  }
  .opacity-05 {
    opacity: 0.05 !important;
  }
  @for $i from 1 through 9 {
    $opacity: ($i / 10);
    &.opacity-#{$i*10} {
      opacity: $opacity !important;
    }
  }

//bg-image
//background-image styles
img.bg-image {
  object-fit: cover;
}
.bg-image {
  &:not([class*="position-absolute"]):not([class*="position-relative"]) {
    position: absolute;
  }
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  + * {
    position: relative;
    z-index: 1;
  }
}

  //Gradient-backgrounds
   // Gradient background
 @mixin bg-gradients($color: $theme-gradients) {
    background-image: $color !important;
  }
  @each $color, $value in $theme-gradients {
    .bg-gradient-#{$color} {
      @include bg-gradients($value);
    }
  }


  //Light(tint) backgrounds
  @mixin bg-tint($color: $theme-colors) {
    background-color: tint-color($color, 90%) !important;
  }
  @each $color, $value in $theme-colors {
    .bg-tint-#{$color} {
      @include bg-tint($value);
    }
  }
  //Dark(shade) backgrounds for colors
  @mixin bg-shade($color: $theme-colors) {
    background-color: shade-color($color, 70%) !important;
  }
  @each $color, $value in $theme-colors {
    .bg-shade-#{$color} {
      @include bg-shade($value);
    }
  }


  //Noise animated background
  .bg-noise{
    position: relative;
    overflow: hidden;
    &:before{
      content: "";
      background-image: url('../img/backgrounds/noise.png'); 
      background-repeat: repeat;
      background-position: top left;
      animation: grain both infinite;
      animation-fill-mode: floor($number: 2);
      position: absolute;
      width:100%;
      height:100%;
      left:0;
      top:0;
      z-index: 0;
    }
  }