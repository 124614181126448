//Swiper slider

.swiper-button-prev,
.swiper-button-next {
  display: flex;
  align-items: center;
  justify-content: center;
  padding:.75rem;
  border-radius: 100%;
  width:3rem;
  height: 3rem;
  &::after{
      display: none;
  }
}
.swiper-pagination-bullet{
    background-color: currentColor;
    .swiper-pagination-bullet-active{
        background-color: currentColor;
    }
}



//when bg dark
.text-white,.text-light{
    color: $white;
   
}


//responsive
@include media-breakpoint-down(md){
    .swiper-button-next,.swiper-button-prev{
        width: 1.75rem;
        padding:0.25rem;
        height: 1.75rem;
        border-radius: 0px;
    }
    .swiper-button-prev, .swiper-container-rtl .swiper-button-next{
        left: -3px;
        right: auto;
    }
    .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
        right: -3px;
        left: auto;
    }
}
.swiper-active-scale{
    .swiper-slide{
        transition: transform .75s cubic-bezier(0.19, 1, 0.22, 1), opacity .35s linear;
        &:not(.swiper-slide-active){
            opacity: .5;
            transform:scale(.9) translateZ(40px);
        }
    }
}

//thumbnails
.swiper-thumbnails{
    .swiper-slide{
        cursor: pointer;
        transition: all .3s ease-in-out;
        opacity: .5;
        &.swiper-slide-thumb-active{
            cursor: none;
            pointer-events: none;
            opacity: 1;
        }
    }
}


//swiper layer animations
.swiper-splitting{
    overflow: hidden;
    .word,&.splitting-link{
        transition: transform .4s cubic-bezier(0,.3,.8,1),opacity .25s linear;
        
        .char{
            opacity: 1;
            transform: none;
        }
    }
    &.splitting-up {
        .char{
            opacity: 1;
            transform: none;
        }
        .word,&.splitting-link{
            opacity: 0;
            transform: translateY(100px);
        }
    }
}
.swiper-slide-active{
    .swiper-splitting{
        .word{
            opacity: 1;
            transition-delay: calc(300ms + (100ms * var(--word-index)));
        }
        &.splitting-link{
            transition-delay: 1s;
            opacity: 1;
        }
        &.splitting-up{
            .char{
                opacity: 1;
                transform: none;
            }
            .word,&.splitting-link{
                transform: translateY(0);
            }
        }
    }
}