// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

$white:     #ffffff;
$gray-100: #f7f7fb !default;
$gray-200: #e5e5e9 !default;
$gray-300: #ceced8 !default;
$gray-400: #b9b9c6 !default;
$gray-500: #8f8f95 !default;
$gray-600: #5b5b61 !default;
$gray-700: #313134 !default;
$gray-800: #252528 !default;
$gray-900: #1d1d1f !default;
$black:    #0f0f10 !default;
// $white:     #ffffff;
// $gray-100: #F7F9FE !default;
// $gray-200: #EAECF2 !default;
// $gray-300: #dcdce1 !default;
// $gray-400: #cacad2 !default;
// $gray-500: #888a93 !default;
// $gray-600: #3d3f49 !default;
// $gray-700: #292E45 !default;
// $gray-800: #1D2237 !default;
// $gray-900: #121625 !default;
// $black:    #0A0D18 !default;
// fusv-disable
$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900,
) !default;
// fusv-enable
$blue:   #4343ff !default;
$indigo: #4422ca !default;
$purple: #d836ec !default;
$pink: #ff3c60 !default;
$red: #ff0000 !default;
$orange: #ff894f !default;
$yellow: #ffc367 !default;
$green: #69d295 !default;
$teal: #48A47D !default;
$cyan: #3beaff !default;

// scss-docs-start colors-map
$colors: (
  "blue": $blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "white": $white,
  "gray": $gray-600,
  "gray-dark": $gray-800,
) !default;
// scss-docs-end colors-map

$primary: $blue !default;
$secondary: $gray-700 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $pink !default;
$light: $gray-100 !default;
$white: $white !default;
$dark: $black !default;

// scss-docs-start theme-colors-map
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "white": $white,
  "dark": $dark,
) !default;
// scss-docs-end theme-colors-map

//
// Gradient system
//
$theme-gradients: () !default;
$theme-gradients: map-merge(
  (
    primary:linear-gradient(-135deg, shade-color($pink,40%) -20%,$primary 130%),
    secondary:-webkit-gradient(linear,left top,left bottom,from(rgba($gray-800,1)),to($black)),
    dark:-webkit-gradient(linear,left top,left bottom,from(rgba($black,.45)),to(rgba($black,1))),
    light:-webkit-gradient(linear,left top,left bottom,from(rgba($light,.25)),to($light)),
    multicolor:conic-gradient(from 90deg at 50% 50%,#8024a0 0deg,#31eec1 33deg,#6924a0 222deg,#d6723b 264deg,#d5498c 295deg,#8024a0 1turn),
  ),
  $theme-gradients
);
// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 3 !default;

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark: $black !default;
$color-contrast-light: $white !default;

// fusv-disable
$blue-100: tint-color($blue, 80%) !default;
$blue-200: tint-color($blue, 60%) !default;
$blue-300: tint-color($blue, 40%) !default;
$blue-400: tint-color($blue, 20%) !default;
$blue-500: $blue !default;
$blue-600: shade-color($blue, 20%) !default;
$blue-700: shade-color($blue, 40%) !default;
$blue-800: shade-color($blue, 60%) !default;
$blue-900: shade-color($blue, 80%) !default;

$indigo-100: tint-color($indigo, 80%) !default;
$indigo-200: tint-color($indigo, 60%) !default;
$indigo-300: tint-color($indigo, 40%) !default;
$indigo-400: tint-color($indigo, 20%) !default;
$indigo-500: $indigo !default;
$indigo-600: shade-color($indigo, 20%) !default;
$indigo-700: shade-color($indigo, 40%) !default;
$indigo-800: shade-color($indigo, 60%) !default;
$indigo-900: shade-color($indigo, 80%) !default;

$purple-100: tint-color($purple, 80%) !default;
$purple-200: tint-color($purple, 60%) !default;
$purple-300: tint-color($purple, 40%) !default;
$purple-400: tint-color($purple, 20%) !default;
$purple-500: $purple !default;
$purple-600: shade-color($purple, 20%) !default;
$purple-700: shade-color($purple, 40%) !default;
$purple-800: shade-color($purple, 60%) !default;
$purple-900: shade-color($purple, 80%) !default;

$pink-100: tint-color($pink, 80%) !default;
$pink-200: tint-color($pink, 60%) !default;
$pink-300: tint-color($pink, 40%) !default;
$pink-400: tint-color($pink, 20%) !default;
$pink-500: $pink !default;
$pink-600: shade-color($pink, 20%) !default;
$pink-700: shade-color($pink, 40%) !default;
$pink-800: shade-color($pink, 60%) !default;
$pink-900: shade-color($pink, 80%) !default;

$red-100: tint-color($red, 80%) !default;
$red-200: tint-color($red, 60%) !default;
$red-300: tint-color($red, 40%) !default;
$red-400: tint-color($red, 20%) !default;
$red-500: $red !default;
$red-600: shade-color($red, 20%) !default;
$red-700: shade-color($red, 40%) !default;
$red-800: shade-color($red, 60%) !default;
$red-900: shade-color($red, 80%) !default;

$orange-100: tint-color($orange, 80%) !default;
$orange-200: tint-color($orange, 60%) !default;
$orange-300: tint-color($orange, 40%) !default;
$orange-400: tint-color($orange, 20%) !default;
$orange-500: $orange !default;
$orange-600: shade-color($orange, 20%) !default;
$orange-700: shade-color($orange, 40%) !default;
$orange-800: shade-color($orange, 60%) !default;
$orange-900: shade-color($orange, 80%) !default;

$yellow-100: tint-color($yellow, 80%) !default;
$yellow-200: tint-color($yellow, 60%) !default;
$yellow-300: tint-color($yellow, 40%) !default;
$yellow-400: tint-color($yellow, 20%) !default;
$yellow-500: $yellow !default;
$yellow-600: shade-color($yellow, 20%) !default;
$yellow-700: shade-color($yellow, 40%) !default;
$yellow-800: shade-color($yellow, 60%) !default;
$yellow-900: shade-color($yellow, 80%) !default;

$green-100: tint-color($green, 80%) !default;
$green-200: tint-color($green, 60%) !default;
$green-300: tint-color($green, 40%) !default;
$green-400: tint-color($green, 20%) !default;
$green-500: $green !default;
$green-600: shade-color($green, 20%) !default;
$green-700: shade-color($green, 40%) !default;
$green-800: shade-color($green, 60%) !default;
$green-900: shade-color($green, 80%) !default;

$teal-100: tint-color($teal, 80%) !default;
$teal-200: tint-color($teal, 60%) !default;
$teal-300: tint-color($teal, 40%) !default;
$teal-400: tint-color($teal, 20%) !default;
$teal-500: $teal !default;
$teal-600: shade-color($teal, 20%) !default;
$teal-700: shade-color($teal, 40%) !default;
$teal-800: shade-color($teal, 60%) !default;
$teal-900: shade-color($teal, 80%) !default;

$cyan-100: tint-color($cyan, 80%) !default;
$cyan-200: tint-color($cyan, 60%) !default;
$cyan-300: tint-color($cyan, 40%) !default;
$cyan-400: tint-color($cyan, 20%) !default;
$cyan-500: $cyan !default;
$cyan-600: shade-color($cyan, 20%) !default;
$cyan-700: shade-color($cyan, 40%) !default;
$cyan-800: shade-color($cyan, 60%) !default;
$cyan-900: shade-color($cyan, 80%) !default;
// fusv-enable

// Characters which are escaped by the escape-svg function
$escaped-characters: (("<", "%3c"), (">", "%3e"), ("#", "%23"), ("(", "%28"), (")", "%29")) !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: false !default;
$enable-rounded: true !default;
$enable-shadows: true !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-reduced-motion: true !default;
$enable-smooth-scroll: true !default;
$enable-grid-classes: true !default;
$enable-button-pointers: true !default;
$enable-rfs: true !default;
$enable-validation-icons: true !default;
$enable-negative-margins: true !default;
$enable-deprecation-messages: true !default;
$enable-important-utilities: true !default;

// Prefix for :root CSS variables

$variable-prefix: bs- !default;

// Gradient
//
// The gradient which is added to components if `$enable-gradients` is `true`
// This gradient is also added to elements with `.bg-gradient`
$gradient: linear-gradient(180deg, rgba($white, 0.15), rgba($white, 0)) !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer / 4,
  2: $spacer / 2,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 3.5,
  7: $spacer * 4,
  8: $spacer * 4.5,
  9: $spacer * 5,
  10: $spacer * 6,
  11: $spacer * 7,
  12: $spacer * 8,
  13: $spacer * 9,
  14: $spacer * 10,
  15: $spacer * 11,
) !default;

$negative-spacers: if($enable-negative-margins, negativify-map($spacers), null) !default;

// Position
//
// Define the edge positioning anchors of the position utilities.

$position-values: (
  0: 0,
  50: 50%,
  100: 100%,
) !default;

// Body
//
// Settings for the `<body>` element.

$body-bg: $white !default;
$body-color: $gray-600 !default;
$body-text-align: null !default;

// Links
//
// Style anchor elements.

$link-color: $primary !default;
$link-decoration: none !default;
$link-shade-percentage: 20% !default;
$link-hover-color: scale-color($link-color, $link-shade-percentage) !default;
$link-hover-decoration: null !default;

$stretched-link-pseudo-element: after !default;
$stretched-link-z-index: 1 !default;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom: $spacer !default;

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 1.5rem !default;
$grid-row-columns: 6 !default;

$gutters: $spacers !default;

// Container padding

$container-padding-x: $grid-gutter-width / 2 !default;

// Components
//
// Define common padding and border radius sizes and more.

$border-width: 1px !default;
$border-widths: (
  0: 0,
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px,
) !default;

$border-color: $gray-200 !default;

$border-radius: .8rem !default;
$border-radius-sm: .4rem !default;
$border-radius-lg: 1.2rem !default;
$border-radius-xl: 2rem !default;
$border-radius-block: 5rem !default;
$border-radius-pill: 50rem !default;
$border-radius-blob:30% 70% 70% 30%/30% 30% 70% 70%;
$box-shadow: 0 6px 12px rgba($black, 0.05) !default;
$box-shadow-sm: 0 2px 4px rgba($black, 0.075) !default;
$box-shadow-lg: 0 16px 32px rgba($black, 0.05) !default;
$box-shadow-inset: inset 0 .5px 1px rgba($black, 0.05) !default;

$component-active-color: $white !default;
$component-active-bg: $primary !default;

$caret-width: 0.25em !default;
$caret-vertical-align: $caret-width * 0.85 !default;
$caret-spacing: $caret-width * 0.85 !default;

$transition-base: all 0.2s ease-in-out !default;
$transition-fade: opacity 0.15s linear !default;
$transition-collapse: height 0.35s ease !default;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif: "Inter", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
  "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-serif:"Abril Fatface",cursive !default;
  $font-family-monospace: "Space Mono", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
// stylelint-enable value-keyword-case
$font-family-base: var(--#{$variable-prefix}font-sans-serif) !default;
$font-family-code: var(--#{$variable-prefix}font-monospace) !default;

// $font-size-root effects the value of `rem`, which is used for as well font sizes, paddings and margins
// $font-size-base effects the font size of the body text
$font-size-root: null !default;
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-sm: $font-size-base * 0.835 !default;
$font-size-lg: $font-size-base * 1.225 !default;

$font-weight-lighter: 200 !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-semibold:600 !default;
$font-weight-bold:700 !default;
$font-weight-bolder:800 !default;
$letter-spacing-base: 0rem !default;
$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.6 !default;
$line-height-sm: 1.2 !default;
$line-height-lg: 2 !default;
$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.275 !default;
$h6-font-size: $font-size-base       !default;

// scss-docs-start font-sizes
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
) !default;

// scss-docs-end font-sizes
$headings-margin-bottom: $spacer / 2 !default;
$headings-font-family: null !default;
$headings-font-style: null !default;

// scss-docs-start display-headings
$display-font-sizes: (
  1: 6rem,
  2: 5rem,
  3: 4.5rem,
  4: 4rem,
  5: 3.25rem,
  6: 2.75rem,
) !default;

// scss-docs-end display-headings
$text-muted: $gray-500 !default;
$blockquote-margin-y: $spacer !default;
$hr-margin-y: $spacer * 2 !default;
$hr-color: currentColor !default;
$hr-height: $border-width !default;
$hr-opacity: 0.1 !default;
$hr-gradient-bg:linear-gradient(180deg, rgba($border-color, 0) 0%, $gray-600 51.09%, rgba($border-color, 0) 100%) !default;
$legend-margin-bottom: 0.5rem !default;
$legend-font-size: 1.5rem !default;
$legend-font-weight: null !default;
$mark-padding: 0.2em !default;
$dt-font-weight: $font-weight-semibold !default;
$nested-kbd-font-weight: $font-weight-semibold !default;
$list-inline-padding: 0.5rem !default;
$mark-bg: $warning !default;
// Headings, Navbar, Dropdown, Buttons Variables
///////////////////////////////////////////////////////////////////////////@at-root
//Headings
$headings-font-family:        $font-family-base !default;
$headings-font-weight:        $font-weight-bolder !default;
$headings-font-weight-sm:     $font-weight-semibold !default;
$headings-line-height:        $line-height-sm !default;
$headings-color:              $black !default;
$headings-letter-spacing:     -.066rem !default;
$headings-letter-spacing-sm:     -.022rem !default;
$display-font-weight: $font-weight-bolder !default;
$display-line-height: $headings-line-height !default;


//Paragraph and blockquote
$lead-font-size: $font-size-lg !default;
$lead-font-weight: $font-weight-normal !default;
$small-font-size: $font-size-sm !default;
$initialism-font-size: $small-font-size !default;
$sub-sup-font-size: 0.75em !default;
$blockquote-font-size: $font-size-lg !default;
$blockquote-footer-color: $text-muted !default;
$blockquote-footer-font-size: $small-font-size !default;

//Input and Buttons

$input-btn-font-family: null !default;
$input-btn-font-size: $font-size-base !default;

$input-btn-font-size-sm: $font-size-sm !default;
$input-btn-font-size-lg: $font-size-base * 1.225 !default;


//Buttons
$btn-font-family:             $font-family-base !default;
$btn-font-size:               $input-btn-font-size !default;
$btn-font-weight:             $font-weight-semibold !default;
$btn-text-transform:         none !default;
$btn-letter-spacing:         -.011rem !default;


//Navbar
$navbar-link-text-transform:          none !default;
$navbar-link-letter-spacing:          -.011rem !default;
$navbar-link-font-weight:             $font-weight-semibold !default;
$navbar-link-font-size:                $font-size-base !default;
$navbar-link-font-family:               $font-family-base !default;

//Dropdown
$dropdown-font-size:$font-size-base !default;
$dropdown-text-transform:capitalize !default;
$dropdown-letter-spacing: $letter-spacing-base !default;
$dropdown-font-family:$font-family-base !default;
$dropdown-font-weight:$font-weight-base !default;


// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: 0.525rem !default;
$input-btn-padding-x: 1rem !default;
$input-btn-line-height: $line-height-base !default;

$input-btn-focus-width: null !default;
$input-btn-focus-color-opacity: 0.25 !default;
$input-btn-focus-color: rgba($component-active-bg, $input-btn-focus-color-opacity) !default;
$input-btn-focus-box-shadow: 0 .5px 1px $input-btn-focus-color !default;

$input-btn-padding-y-sm: 0.275rem !default;
$input-btn-padding-x-sm: 0.75rem !default;

$input-btn-padding-y-lg: .875rem !default;
$input-btn-padding-x-lg: 1.25rem !default;

$input-btn-border-width: $border-width !default;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.
$btn-padding-y: $input-btn-padding-y !default;
$btn-padding-x: 1.5rem !default;

$btn-line-height: $input-btn-line-height !default;
$btn-white-space: nowrap !default; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm: $input-btn-padding-y-sm !default;
$btn-padding-x-sm: .875rem !default;
$btn-font-size-sm: $input-btn-font-size-sm !default;

$btn-padding-y-lg: $input-btn-padding-y-lg !default;
$btn-padding-x-lg: 2.25rem !default;
$btn-font-size-lg: $input-btn-font-size-lg !default;

$btn-border-width: $input-btn-border-width !default;

$btn-box-shadow: 0 0 0 rgba($white, 0), 0 0 0 rgba($black, 0) !default;
$btn-focus-width: $input-btn-focus-width !default;
$btn-focus-box-shadow: $input-btn-focus-box-shadow !default;
$btn-disabled-opacity: 0.4 !default;
$btn-active-box-shadow: null !default;

$btn-link-color: $link-color !default;
$btn-link-hover-color: $link-hover-color !default;
$btn-link-disabled-color: $gray-600 !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: $border-radius !default;
$btn-border-radius-sm: $border-radius-sm !default;
$btn-border-radius-lg: $border-radius-lg !default;

$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out !default;

// Forms

$form-text-margin-top: 0.25rem !default;
$form-text-font-size: $small-font-size !default;
$form-text-font-style: null !default;
$form-text-font-weight: null !default;
$form-text-color: $text-muted !default;
$form-label-margin-bottom: 0.25rem !default;
$form-label-font-size: $font-size-sm !default;
$form-label-font-style: null !default;
$form-label-font-weight: null !default;
$form-label-color: $text-muted !default;

$input-padding-y: $input-btn-padding-y !default;
$input-padding-x: $input-btn-padding-x !default;
$input-font-family: $input-btn-font-family !default;
$input-font-size: $input-btn-font-size !default;
$input-font-weight: $font-weight-base !default;
$input-line-height: $input-btn-line-height !default;

$input-padding-y-sm: $input-btn-padding-y-sm !default;
$input-padding-x-sm: $input-btn-padding-x-sm !default;
$input-font-size-sm: $input-btn-font-size-sm !default;

$input-padding-y-lg: $input-btn-padding-y-lg !default;
$input-padding-x-lg: $input-btn-padding-x-lg !default;
$input-font-size-lg: $input-btn-font-size-lg !default;

$input-bg: $white !default;
$input-disabled-bg: $gray-100 !default;
$input-disabled-border-color: null !default;

$input-color: $body-color !default;
$input-border-color: $border-color !default;
$input-border-width: $input-btn-border-width !default;
$input-box-shadow: $box-shadow-inset !default;

$input-border-radius: $border-radius !default;
$input-border-radius-sm: $border-radius-sm !default;
$input-border-radius-lg: $border-radius-lg !default;

$input-focus-bg: $input-bg !default;
$input-focus-border-color: tint-color($component-active-bg, 50%) !default;
$input-focus-color: $input-color !default;
$input-focus-width: $input-btn-focus-width !default;
$input-focus-box-shadow: $input-box-shadow, 0 0 4px rgba($primary,0.3) !default;

$input-placeholder-color: $gray-500 !default;
$input-plaintext-color: $body-color !default;
$input-height-border: $input-border-width * 2 !default;

$input-height-inner: add($input-line-height * 1em, $input-padding-y * 2) !default;
$input-height-inner-half: add($input-line-height * 0.5em, $input-padding-y) !default;
$input-height-inner-quarter: add($input-line-height * 0.25em, $input-padding-y / 2) !default;
$input-height-border:                   $input-border-width * 1 !default;
$input-height: add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false)) !default;
$input-height-sm: add($input-line-height * 1em, add($input-padding-y-sm * 2, $input-height-border, false)) !default;
$input-height-lg: add($input-line-height * .9375em, add($input-padding-y-lg * 2, $input-height-border, false)) !default;

$input-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;

//addon group
$input-group-addon-bg:                  $gray-100 !default;


//file
$form-file-button-color:          $input-color !default;
$form-file-button-bg:             $input-group-addon-bg !default;
$form-file-button-hover-bg:       shade-color($form-file-button-bg, 2%) !default;


//Form check and radio
$form-check-input-bg:                     transparent !default;
$form-check-input-border:                 1px solid $border-color !default;
$form-check-input-border-radius:          $border-radius-sm !default;
$form-check-input-focus-border:           $input-focus-border-color !default;
$form-check-input-focus-box-shadow:       $input-btn-focus-box-shadow !default;

$form-check-input-checked-color:          $component-active-color !default;
$form-check-input-checked-bg-color:       $component-active-bg !default;
$form-check-input-checked-border-color:   $form-check-input-checked-bg-color !default;
$form-check-input-checked-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/></svg>") !default;
$form-check-radio-checked-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>") !default;
$form-check-label-color:                  $gray-600 !default;


$form-range-track-width:          100% !default;
$form-range-track-height:         .25rem !default;
$form-range-track-cursor:         pointer !default;
$form-range-track-bg:             $light !default;
$form-range-track-border-radius:  $border-radius-sm !default;
$form-range-track-box-shadow:     none !default;

$form-range-thumb-width:                   1rem !default;
$form-range-thumb-height:                  $form-range-thumb-width !default;
$form-range-thumb-bg:                      $component-active-bg !default;
$form-range-thumb-border:                  0 !default;
$form-range-thumb-border-radius:           1rem !default;
$form-range-thumb-box-shadow:              none !default;
$form-range-thumb-focus-box-shadow:        none !default;
$form-range-thumb-focus-box-shadow-width:  $input-focus-width !default; // For focus box shadow issue in Edge
$form-range-thumb-active-bg:               tint-color($component-active-bg, 70%) !default;
$form-range-thumb-disabled-bg:             $gray-500 !default;        



//Select
$form-select-focus-border-color:  $input-focus-border-color !default;
$form-select-focus-width:         $input-focus-width !default;
$form-select-focus-box-shadow:    $input-focus-box-shadow !default;

$form-switch-color:               $border-color !default;
$form-switch-width:               2.25rem !default;
$form-switch-padding-start:       $form-switch-width + .5rem !default;
$form-switch-bg-image:            url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color}'/></svg>") !default;
$form-switch-border-radius:       $form-switch-width / .5 !default;

$form-switch-focus-color:         $input-focus-border-color !default;
$form-switch-focus-bg-image:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-focus-color}'/></svg>") !default;

$form-switch-checked-color:       $component-active-color !default;
$form-switch-checked-bg-image:    url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-checked-color}'/></svg>") !default;
$form-switch-checked-bg-position: right center !default;


// Navs

$nav-link-padding-y: 0.5rem !default;
$nav-link-padding-x: 1rem !default;
$nav-link-font-size: $font-size-base !default;
$nav-link-font-weight: $font-weight-base !default;
$nav-link-color: null !default;
$nav-link-hover-color: null !default;
$nav-link-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out !default;
$nav-link-disabled-color: $gray-600 !default;

$nav-tabs-border-color: $gray-300 !default;
$nav-tabs-border-width: $border-width !default;
$nav-tabs-border-radius: $border-radius !default;
$nav-tabs-link-hover-border-color: $gray-200 $gray-200 $nav-tabs-border-color !default;
$nav-tabs-link-active-color: $gray-700 !default;
$nav-tabs-link-active-bg: $body-bg !default;
$nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg !default;

$nav-pills-border-radius: $border-radius !default;
$nav-pills-link-active-color: $component-active-color !default;
$nav-pills-link-active-bg: $component-active-bg !default;

// Navbar

$navbar-padding-y: $spacer / 2 !default;
$navbar-padding-x: null !default;

$navbar-nav-link-padding-x: .75rem !default;

$navbar-brand-font-size: $font-size-lg !default;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height: $font-size-base * $line-height-base + $nav-link-padding-y * 2 !default;
$navbar-brand-height: $navbar-brand-font-size * $line-height-base !default;
$navbar-brand-padding-y: ($nav-link-height - $navbar-brand-height) / 2 !default;
$navbar-brand-margin-right: 1rem !default;

$navbar-toggler-padding-y: 0.25rem !default;
$navbar-toggler-padding-x: 0.75rem !default;
$navbar-toggler-font-size: $font-size-lg !default;
$navbar-toggler-border-radius: $btn-border-radius !default;
$navbar-toggler-focus-width: null !default;
$navbar-toggler-transition: box-shadow 0.15s ease-in-out !default;

$navbar-dark-color: rgba($white, 0.55) !default;
$navbar-dark-hover-color: rgba($white, 0.75) !default;
$navbar-dark-active-color: $white !default;
$navbar-dark-disabled-color: rgba($white, 0.25) !default;
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg viewBox='0 0 24 24' fill='#{$navbar-dark-color}' xmlns='http://www.w3.org/2000/svg'><path d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/></svg>") !default;
$navbar-dark-toggler-border-color: rgba($white, 0.1) !default;

$navbar-light-color: $gray-600 !default;
$navbar-light-hover-color: rgba($primary, 1) !default;
$navbar-light-active-color: rgba($primary, 1) !default;
$navbar-light-disabled-color: rgba($text-muted, 0.75) !default;
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg viewBox='0 0 24 24' fill='#{$navbar-light-color}' xmlns='http://www.w3.org/2000/svg'><path d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/></svg>") !default;
$navbar-light-toggler-border-color: rgba($black, 0.1) !default;

$navbar-light-brand-color: $navbar-light-active-color !default;
$navbar-light-brand-hover-color: $navbar-light-active-color !default;
$navbar-dark-brand-color: $navbar-dark-active-color !default;
$navbar-dark-brand-hover-color: $navbar-dark-active-color !default;

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width: 14rem !default;
$dropdown-padding-x: 0 !default;
$dropdown-padding-y: 0.75rem !default;
$dropdown-spacer: 0rem !default;

$dropdown-color: $body-color !default;
$dropdown-bg: $white !default;
$dropdown-border-color: transparent !default;
$dropdown-border-radius: $border-radius-lg !default;
$dropdown-border-width: 0px !default;
$dropdown-inner-border-radius: subtract($dropdown-border-radius, $dropdown-border-width) !default;
$dropdown-divider-bg: $dark !default;
$dropdown-divider-margin-y: $spacer / 1.5 !default;
$dropdown-box-shadow: 0 2px 4px rgba($black,.075) !default;

$dropdown-link-color: $gray-600 !default;
$dropdown-link-hover-color: shade-color($body-color, 10%) !default;
$dropdown-link-hover-bg: transparent !default;

$dropdown-link-active-color: $dropdown-link-hover-color !default;
$dropdown-link-active-bg: transparent !default;

$dropdown-link-disabled-color: $text-muted !default;
$dropdown-item-padding-y: $spacer / 2.75 !default;
$dropdown-item-padding-x: $spacer !default;

$dropdown-header-color: $text-muted !default;
$dropdown-header-padding: 0.15rem $dropdown-item-padding-x !default;

$dropdown-dark-color: rgba($white,.6) !default;
$dropdown-dark-bg: $secondary !default;
$dropdown-dark-border-color: $gray-600 !default;
$dropdown-dark-divider-bg: $white !default;
$dropdown-dark-box-shadow: null !default;
$dropdown-dark-link-color: $dropdown-dark-color !default;
$dropdown-dark-link-hover-color:rgba($white,.85) !default;
$dropdown-dark-link-hover-bg: transparent !default;
$dropdown-dark-link-active-color: $white !default;
$dropdown-dark-link-active-bg: transparent !default;
$dropdown-dark-link-disabled-color: rgba($white,.35) !default;
$dropdown-dark-header-color: $gray-600 !default;

// Breadcrumbs

$breadcrumb-font-size: $font-size-sm !default;
$breadcrumb-padding-y: 0 !default;
$breadcrumb-padding-x: 0 !default;
$breadcrumb-item-padding-x: 0.5rem !default;
$breadcrumb-margin-bottom: 0 !default;
$breadcrumb-bg: null !default;
$breadcrumb-divider-color: rgba($black, 0.225) !default;
$breadcrumb-active-color: $text-muted !default;
$breadcrumb-divider: quote("\f280") !default;
$breadcrumb-divider-flipped: $breadcrumb-divider !default;
$breadcrumb-border-radius: null !default;

// Pagination

$pagination-padding-y: 0.375rem !default;
$pagination-padding-x: 0.75rem !default;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;

$pagination-color: $link-color !default;
$pagination-bg: $white !default;
$pagination-border-width: $border-width !default;
$pagination-border-radius: $border-radius !default;
$pagination-margin-start: -$pagination-border-width !default;
$pagination-border-color: $gray-300 !default;

$pagination-focus-color: $link-hover-color !default;
$pagination-focus-bg: $gray-200 !default;
$pagination-focus-box-shadow: $input-btn-focus-box-shadow !default;
$pagination-focus-outline: 0 !default;

$pagination-hover-color: $link-hover-color !default;
$pagination-hover-bg: $gray-200 !default;
$pagination-hover-border-color: $gray-300 !default;

$pagination-active-color: $component-active-color !default;
$pagination-active-bg: $component-active-bg !default;
$pagination-active-border-color: $pagination-active-bg !default;

$pagination-disabled-color: $gray-600 !default;
$pagination-disabled-bg: $white !default;
$pagination-disabled-border-color: $gray-300 !default;

$pagination-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out !default;

// Cards

$card-spacer-y: $spacer !default;
$card-spacer-x: $spacer !default;
$card-title-spacer-y: $spacer / 2 !default;
$card-border-width: $border-width !default;
$card-border-radius: $border-radius !default;
$card-border-color: $border-color !default;
$card-inner-border-radius: subtract($card-border-radius, $card-border-width) !default;
$card-cap-padding-y: $card-spacer-y / 2 !default;
$card-cap-padding-x: $card-spacer-x !default;
$card-cap-bg: rgba($black, 0.03) !default;
$card-cap-color: null !default;
$card-height: null !default;
$card-color: null !default;
$card-bg: $white !default;

$card-img-overlay-padding: $spacer !default;

$card-group-margin: $grid-gutter-width / 2 !default;

// Accordion
$accordion-padding-y: 1rem !default;
$accordion-padding-x: 1.25rem !default;
$accordion-color: $dark !default;
$accordion-bg: transparent !default;
$accordion-border-width: $border-width !default;
$accordion-border-color: $border-color !default;
$accordion-border-radius: $border-radius !default;

$accordion-body-padding-y: $accordion-padding-y !default;
$accordion-body-padding-x: $accordion-padding-x !default;

$accordion-button-padding-y: $accordion-padding-y !default;
$accordion-button-padding-x: $accordion-padding-x !default;
$accordion-button-color: $accordion-color !default;
$accordion-button-bg: $accordion-bg !default;
$accordion-transition: $btn-transition, border-radius 0.15s ease !default;
$accordion-button-active-bg: $gray-100 !default;
$accordion-button-active-color: shade-color($primary, 1%) !default;

$accordion-button-focus-border-color: $border-color !default;
$accordion-button-focus-box-shadow: none !default;

$accordion-icon-width: 1rem !default;
$accordion-icon-color: $accordion-color !default;
$accordion-icon-active-color: $accordion-button-active-color !default;
$accordion-icon-transition: transform 0.2s ease-in-out !default;
$accordion-icon-transform: rotate(180deg) !default;

$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
$accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;

// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y: $spacer * 1.25 !default;
$alert-padding-x: $spacer * 1.25 !default;
$alert-margin-bottom: 1rem !default;
$alert-border-radius: $border-radius-lg !default;
$alert-link-font-weight: $font-weight-bold !default;
$alert-border-width: $border-width !default;

$alert-bg-scale: -90% !default;
$alert-border-scale: -85% !default;
$alert-color-scale: 20% !default;

$alert-dismissible-padding-r: $alert-padding-x * 3 !default; // 3x covers width of x plus default padding on either side

// Toasts

$toast-max-width: 320px !default;
$toast-padding-x: 0.75rem !default;
$toast-padding-y: 0.5rem !default;
$toast-font-size: $font-size-sm !default;
$toast-color: null !default;
$toast-background-color: rgba($white, 0.85) !default;
$toast-border-width: 1px !default;
$toast-border-color: $border-color !default;
$toast-border-radius: $border-radius-lg !default;
$toast-box-shadow: $box-shadow !default;
$toast-spacing: $container-padding-x !default;

$toast-header-color: $body-color !default;
$toast-header-background-color: rgba($white, 0.85) !default;
$toast-header-border-color: $border-color !default;

// Close

$btn-close-width: 14px !default;
$btn-close-height: $btn-close-width !default;
$btn-close-padding-x: 0.1em !default;
$btn-close-padding-y: $btn-close-padding-x !default;
$btn-close-color: $black !default;
$btn-close-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$btn-close-color}'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>") !default;
$btn-close-focus-shadow: none !default;
$btn-close-opacity: 0.5 !default;
$btn-close-hover-opacity: 1 !default;
$btn-close-focus-opacity: 1 !default;
$btn-close-disabled-opacity: 0.25 !default;
$btn-close-white-filter: invert(1) grayscale(100%) brightness(200%) !default;



// Badges

$badge-font-size:                   $font-size-sm !default;
$badge-font-weight:                 $font-weight-normal !default;
$badge-color:                       $white !default;
$badge-padding-y:                   .125rem !default;
$badge-padding-x:                   .5em !default;
$badge-border-radius:               $border-radius !default;


//tooltips


$tooltip-font-size:                 $font-size-sm !default;
$tooltip-max-width:                 200px !default;
$tooltip-color:                     $white !default;
$tooltip-bg:                        $dark !default;
$tooltip-border-radius:             $border-radius !default;
$tooltip-opacity:                   .9 !default;
$tooltip-padding-y:                 $spacer / 4 !default;
$tooltip-padding-x:                 $spacer / 2 !default;
$tooltip-margin:                    0 !default;

$tooltip-arrow-width:               .8rem !default;
$tooltip-arrow-height:              .4rem !default;
$tooltip-arrow-color:               $tooltip-bg !default;


// Modals

// Padding applied to the modal body
$modal-inner-padding:               $spacer !default;

// Margin between elements in footer, must be lower than or equal to 2 * $modal-inner-padding
$modal-footer-margin-between:       .5rem !default;

$modal-dialog-margin:               .5rem !default;
$modal-dialog-margin-y-sm-up:       1.75rem !default;

$modal-title-line-height:           $line-height-base !default;

$modal-content-color:               null !default;
$modal-content-bg:                  $white !default;
$modal-content-border-color:        $gray-200 !default;
$modal-content-border-width:        $border-width !default;
$modal-content-border-radius:       $border-radius-lg !default;
$modal-content-inner-border-radius: subtract($modal-content-border-radius, $modal-content-border-width) !default;
$modal-content-box-shadow-xs:       $box-shadow-sm !default;
$modal-content-box-shadow-sm-up:    $box-shadow !default;

$modal-backdrop-bg:                 $black !default;
$modal-backdrop-opacity:            .5 !default;
$modal-header-border-color:         $border-color !default;
$modal-footer-border-color:         $modal-header-border-color !default;
$modal-header-border-width:         $modal-content-border-width !default;
$modal-footer-border-width:         $modal-header-border-width !default;
$modal-header-padding-y:            $modal-inner-padding !default;
$modal-header-padding-x:            $modal-inner-padding !default;
$modal-header-padding:              $modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility

$modal-sm:                          300px !default;
$modal-md:                          500px !default;
$modal-lg:                          800px !default;
$modal-xl:                          1140px !default;

$modal-fade-transform:              translate(0, -50px) !default;
$modal-show-transform:              none !default;
$modal-transition:                  transform .3s ease-out !default;
$modal-scale-transform:             scale(1.02) !default;



// List group

$list-group-color:                  null !default;
$list-group-bg:                     $white !default;
$list-group-border-color:           $border-color !default;
$list-group-border-width:           $border-width !default;
$list-group-border-radius:          $border-radius !default;

$list-group-item-padding-y:         $spacer / 2 !default;
$list-group-item-padding-x:         $spacer !default;
$list-group-item-bg-scale:          -80% !default;
$list-group-item-color-scale:       40% !default;

$list-group-hover-bg:               $gray-100 !default;
$list-group-active-color:           $component-active-color !default;
$list-group-active-bg:              $component-active-bg !default;
$list-group-active-border-color:    $list-group-active-bg !default;

$list-group-disabled-color:         $text-muted !default;
$list-group-disabled-bg:            transparent !default;

$list-group-action-color:           $headings-color !default;
$list-group-action-hover-color:     $list-group-action-color !default;

$list-group-action-active-color:    $body-color !default;
$list-group-action-active-bg:       $gray-100 !default;


//Brand Colors map
$brand-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$instagram:radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%) !default;
$brand-colors: map-merge(
  (
    "facebook": #3b5998,
    "twitter": #1da1f2,
    "google": #ff0000,
    "skype": #00aff0,
    "linkedin": #0077b5,
    "pinterest": #c8232c,
    "tumblr": #34526f,
    "vimeo": #33b3f2,
    "digg": #191919,
    "reddit": #c6c6c6,
    "spotify": #1ed760,
    "blogger": #fc4f08,
    "dribbble": #ea4c89,
    "flickr": #ff0084,
    "youtube": #ff0000,
    "yahoo": #720e9e,
    "dropbox": #3d9ae8,
    "ebay": #89c507,
    "github": #171515,
    "google-play": #dd4b39,
    "itunes": #222,
    "soundcloud": #f70,
    "wordpress": #1e8cbe,
    "yelp": #c41200,
    "drupal": #27537a,
    "bitbucket": #0e4984,
    "stackoverflow": #ef8236,
    "xing": #126567,
    "amazon": #e47911,
    "appstore": #0070c9,
    "android": #a4c639,
    "wikipedia": #333,
    "foursquare": #25a0ca,
    "bitcoin": #ecbc13,
    "adobe": #ff0000,
    "chrome": #174ea6,
    "gulp": #cf4647,
    "sass": #b37399,
    "paypal": #0070ba,
    "magento": #f26322,
    "microsoft": #0067b8,
    "angular": #dd4b39,
    "slack": rgb(224, 30, 90),
    "behance": #053eff,
    "stumbleupon": #f74425,
    "internet-explorer": #0084ff,
    "whatsapp":#25D366,
    "twitch":#6441a5,
  ),
  $brand-colors
);
