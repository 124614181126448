body {
    letter-spacing: $letter-spacing-base;
    -webkit-font-smoothing: antialiased;
}

*:focus {
    outline: 0 !important;
}
a {
    transition: $transition-base;
}
//Headings
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    font-family: $headings-font-family;
}
h1,
h2,
.h1,
.h2{
    letter-spacing: $headings-letter-spacing;
}
h3,h4,
h5,
h6,
.h3,.h4,
.h5,
.h6{
    letter-spacing: $headings-letter-spacing-sm;
    font-weight: $headings-font-weight-sm;
}

//letter-spacing
@for $i from 0 through 9 {
    $size: ($i / 2);
    &.letter-spacing-#{$i} {
        letter-spacing: #{$size}px;
    }
}

//Text styles
.text-serif{
    font-family: $font-family-serif!important;
    letter-spacing: 0;
    font-weight: $font-weight-normal;
}
.text-mono {
    font-family: $font-family-monospace!important;
    font-weight: $font-weight-normal;
}
.text-italic {
    font-style: italic;
}
b,
strong {
    font-weight: $font-weight-semibold;
}
.fw-semibold {
    font-weight: $font-weight-semibold;
}

//Typography for dark backgrounds
.text-white,
.text-light {
    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6 {
        color: $white;
    }
    .text-muted {
        color: rgba($white, 0.54) !important;
    }

    a:not(.btn):not(.dropdown-item):not([class^="text-"]):not([class*=" text-"]) {
        color: rgba($white, 0.7) !important;
        transition: $transition-base;
        &:hover:not(.active):not(.disabled):not(:disabled) {
            color: rgba($white, 1) !important;
        }
    }
    a.text-muted {
        color: rgba($white, 0.54) !important;
        &:hover:not(.active):not(.disabled):not(:disabled) {
            color: rgba($white, 1) !important;
        }
    }
}

//Footer
footer:not(.text-white):not(.text-light) {
    a:not(.btn):not(.dropdown-item):not(.text-muted) {
        color: rgba($body-color, 1) !important;
        transition: $transition-base;
        &:hover:not(.active):not(.disabled):not(:disabled) {
            color: rgba($primary, 1) !important;
        }
    }
    a.text-muted {
        color: $text-muted !important;
        &:hover:not(.active):not(.disabled):not(:disabled) {
            color: rgba($dark, 1) !important;
        }
    }
}

//A colors
a {
    &.text-dark,
    &.text-muted,
    &.text-secondary,
    &.text-body {
        &:hover {
            color: $primary !important;
        }
    }
}

//Text gradients
.text-gradient {
    text-shadow: none !important;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(20deg, $primary 10%, $danger 90%) !important;
}

//Dropcap text
.dropcap::first-letter {
    float: left;
    line-height: 0.5;
    font-weight: 600;
    font-size: 2.5rem;
    margin: 0 6px 0 0;
    color: $primary;
}

//TextOutline
.text-outline {
    -webkit-text-stroke-width: 1px;
    text-decoration: none !important;
}
@supports (-webkit-text-stroke: 1px) {
    @each $color, $value in $theme-colors {
        .text-outline.text-#{$color} {
            -webkit-text-stroke: 1px $value;
            -webkit-text-fill-color: transparent;
            color: transparent !important;
        }
    }
}

//Text underline with colors
@each $color, $value in $theme-colors {
    .text-underline-#{$color} {
        text-decoration: $value solid underline !important;
    }
}

