// Animations
@-webkit-keyframes transform-x {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        -webkit-animation-timing-function: ease;
        animation-timing-function: ease
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes  transform-x {
    0% {
        opacity: 0;
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        -webkit-animation-timing-function: ease;
        animation-timing-function: ease
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@-webkit-keyframes transform-y {
    0% {
        opacity: 0;
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
        -webkit-animation-timing-function: ease;
        animation-timing-function: ease
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes  transform-y {
    0% {
        opacity: 0;
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
        -webkit-animation-timing-function: ease;
        animation-timing-function: ease
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}



@-webkit-keyframes scaleIn {
    0% {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: left;
        transform-origin: left;
    }

    to {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transform-origin: left;
        transform-origin: left;
    }
}
@keyframes scaleIn {
    0% {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: left;
        transform-origin: left;
    }

    to {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transform-origin: left;
        transform-origin: left;
    }
}

@-webkit-keyframes scaleOut {
    0% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transform-origin: right;
        transform-origin: right;
    }

    to {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: right;
        transform-origin: right;
    }
}

@keyframes scaleOut {
    0% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transform-origin: right;
        transform-origin: right;
    }

    to {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: right;
        transform-origin: right;
    }
}

@-webkit-keyframes fadeDown {
    0% {
        -webkit-transform: translateY(-22px);
        transform: translateY(-22px);
        opacity: 0;
    }

    100% {
        -webkit-transform: none;
        transform: none;
        opacity: 1;
    }
}

@keyframes fadeDown {
    0% {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
        opacity: 0;
    }
    100% {
        -webkit-transform: none;
        transform: none;
        opacity: 1;
    }
}
@keyframes underline-in {
    0% {
        transform: scaleX(0);
        transform-origin: left center;
    }
    to {
        transform: scaleX(1);
        transform-origin: left center;
    }
}
@keyframes underline-out {
    0% {
        transform: scaleX(1);
        transform-origin: right center;
    }
    to {
        transform: scaleX(0);
        transform-origin: right center;
    }
}
@keyframes underline-out-in {
    0% {
        transform: scaleX(1);
        transform-origin: right center;
    }
    50% {
        transform: scaleX(0);
        transform-origin: right center;
    }
    51% {
        transform: scaleX(0);
        transform-origin: left center;
    }
    to {
        transform: scaleX(1);
        transform-origin: left center;
    }
}


//blink from typed.js
@keyframes blink{
    0% { opacity:1; }
    50% { opacity:0; }
    100% { opacity:1; }
}
@-webkit-keyframes blink{
    0% { opacity:1; }
    50% { opacity:0; }
    100% { opacity:1; }
}


//verticle line
@keyframes vertical {
	0% {
		height: 0px;
		bottom: auto;
		top: 33px
	}
	49.9% {
		bottom: auto;
		top: 33px
	}
	50% {
		height: 50px;
		top: auto;
		bottom: 0
	}
	100% {
		height: 0px;
		top: auto;
		bottom: 0
	}
}
@-webkit-keyframes vertical {
	0% {
		height: 0px;
		bottom: auto;
		top: 33px
	}
	49.9% {
		bottom: auto;
		top: 33px
	}
	50% {
		height: 50px;
		top: auto;
		bottom: 0
	}
	100% {
		height: 0px;
		top: auto;
		bottom: 0
	}
}
@keyframes verticalh {
	0% {
		bottom: auto;
		top: 33px
	}
	49.9% {
		bottom: auto;
		top: 33px
	}
	50% {
		height: 50px;
		top: auto;
		bottom: 0
	}
	100% {
		height: 50px;
		top: auto;
		bottom: 0
	}
}
@-webkit-keyframes verticalh {
	0% {
		bottom: auto;
		top: 33px
	}
	49.9% {
		bottom: auto;
		top: 33px
	}
	50% {
		height: 50px;
		top: auto;
		bottom: 0
	}
	100% {
		height: 50px;
		top: auto;
		bottom: 0
	}
}


@keyframes pulse {
    from {
      transform: scale(0);
      opacity:1;
    }
  
    50% {
      transform: scale(1.25);
      opacity:0;
    }
  
    to {
      transform: scale3d(0);
      opacity:0;
    }
  }


  @keyframes grain {
    0%, 100% { transform:translate(0, 0); }
    10% { transform:translate(-5%, -10%); }
    20% { transform:translate(-15%, 5%); }
    30% { transform:translate(7%, -25%); }
    40% { transform:translate(-5%, 25%); }
    50% { transform:translate(-15%, 10%); }
    60% { transform:translate(15%, 0%); }
    70% { transform:translate(0%, 15%); }
    80% { transform:translate(3%, 35%); }
    90% { transform:translate(-10%, 10%); }
  }

  @-webkit-keyframes grain {
    0%, 100% { transform:translate(0, 0); }
    10% { transform:translate(-5%, -10%); }
    20% { transform:translate(-15%, 5%); }
    30% { transform:translate(7%, -25%); }
    40% { transform:translate(-5%, 25%); }
    50% { transform:translate(-15%, 10%); }
    60% { transform:translate(15%, 0%); }
    70% { transform:translate(0%, 15%); }
    80% { transform:translate(3%, 35%); }
    90% { transform:translate(-10%, 10%); }
  }