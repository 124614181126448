.fullscreen-close{
  opacity: 0;
  transform:translateY(-200%);
    transition: opacity 400ms linear 300ms, transform 600ms cubic-bezier(0.215, 0.61, 0.355, 1) 300ms;
}
.fullscreen-wrapper{
    top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: fixed;
      width: 100%;
      left: 0;
      top: 0;
      overflow: hidden;
      z-index: 8999;
      justify-content: flex-end;
      visibility: hidden;
    transition: visibility 0ms linear 1250ms, transform 500ms cubic-bezier(0.215, 0.61, 0.355, 1);
    &::before{
      will-change: transform;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: shade-color($primary,30%);
      transform: translateY(-100%);
      transition: transform 500ms cubic-bezier(0.55, 0.055, 0.675, 0.19) 200ms;
    }
  }
  .fullscreen-inner{
    display:flex;
    width:100%;
    height:100%;
    align-items:center;
    padding:2rem;
    form{
      position: relative;
      width: 100%;
      transform: translateY(3.5rem);
      opacity: 0;
      transition: opacity 400ms linear 0ms, transform 500ms cubic-bezier(0.55, 0.055, 0.675, 0.19) 0ms;
      .form-control{
        background-color:transparent;
        padding-left:0;height:56px;
      }
    }
  }
  
  .fullscreen-open{
    .fullscreen-toggler{
      .nav-link{
        color: $white!important;
      }
    }
    .fullscreen-wrapper{
      visibility: visible;
      transition: visibility 0ms linear 0ms;
      will-change: transform;
      &::before{
      transform: translateY(0%); 
      transition: transform 750ms cubic-bezier(0.215, 0.61, 0.355, 1);
  }
      .fullscreen-inner{
        form{
              transform: translateY(0);
      opacity: 1;
      transition: opacity 600ms linear 500ms, transform 600ms cubic-bezier(0.215, 0.61, 0.355, 1) 500ms;
        }
      }
    }
    .fullscreen-close{
      opacity:1;
      transform:translateY(0);
      transition: opacity 600ms linear 600ms, transform 600ms cubic-bezier(0.215, 0.61, 0.355, 1) 600ms;
    }
  }