//Navbar styles
.navbar {
    
    .navbar-nav {
        .nav-link {
            text-transform: $navbar-link-text-transform;
            font-weight: $navbar-link-font-weight;
            font-size: $navbar-link-font-size;
            font-family: $navbar-link-font-family;
            letter-spacing: $navbar-link-letter-spacing;
        }
    }
    &:not(.position-fixed):not(.position-absolute){
        position: relative;
    }
}


//Navbar no collapse items style
.navbar-no-collapse-items {
    .btn {
        white-space: nowrap;
    }
    .nav-link{
        padding: .5rem 0;
        color: $navbar-light-color;
        &:hover{
            color: $navbar-light-hover-color;
        }
    }
}
.navbar-link-white{
    .navbar-no-collapse-items{
        .nav-link{
            color: $navbar-dark-color;
            &:hover,&:focus{
                color: $navbar-dark-hover-color;
            }
            &.active,&.show{
                color: $navbar-dark-active-color;
            }
        }
    }
}
.scroll-up,.is-sticky{
    .navbar-light.navbar-link-white{
        .navbar-no-collapse-items{
            .nav-link{
                color: $navbar-light-color;
                &:hover,&:focus{
                    color: $navbar-light-hover-color;
                }
                &.active,&.show{
                    color: $navbar-light-active-color;
                }
            }
        }
    }
}
.header-transparent {
    .navbar {
        background-color: transparent;
        border-bottom: 0px;
    }
}
.header-absolute-top {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: $zindex-fixed;
}
.header-fixed-top {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: $zindex-fixed;
}
.navbar-brand {
    width: $spacer * 6;
    display: block;

    min-height: $spacer * 1.75;
    position: relative;
    .navbar-brand-transparent,
    .navbar-brand-sticky {
        position: absolute;
        top: 50%;
        width: 100%;
        transform: translateY(-50%);
    }
}

@include media-breakpoint-up(lg) {
    header.header-absolute-top{
        .navbar{
            max-width:calc(100vw - 17px);
        }
    }
    .navbar.navbar-brand-center {
        .navbar-brand {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
    header {
        &.sticky-reverse.scroll-up,
        &.header-sticky.is-sticky {
            .navbar.navbar-expand-lg {
                border-radius: 0px!important;
                position: fixed;
                left: 0;
                top: 0;
                z-index: $zindex-fixed;
                width: 100%;
                transition: all 0.3s;
                animation: fadeDown 0.4s both;
                -webkit-transition: all 0.4s;
                border-width: 1px;
                &.navbar-light {
                    background-color: rgba($white, 1);
                    animation: fadeDown 0.4s both;
                    -webkit-animation: fadeDown 0.4s both;
                    border-bottom-color: $border-color;
                }
                &.navbar-dark {
                    background-color: rgba($dark, 1);
                    animation: fadeDown 0.4s both;
                    -webkit-animation: fadeDown 0.4s both;
                    box-shadow: 0px 0.75rem 1.5rem rgba($dark, 0.225);
                }
            }
        }
    }
    header:not(.scroll-up):not(.is-sticky) {
        .navbar-light.navbar-link-white {
            .navbar-nav {
                .nav-link {
                    color: $navbar-dark-color;
                    &:hover,
                    &:focus {
                        color: $navbar-dark-hover-color;
                    }
                    &.active {
                        color: $navbar-dark-active-color;
                    }
                }
            }
        }
    }
    .navbar-expand-lg {
        .navbar-brand {
            width: $spacer * 7;
        }
        &.navbar {
            padding-top: 0;
            padding-bottom: 0;
        }
        .nav-item>.nav-link {
            height: 4.75rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .navbar-no-collapse-items{
            .nav-item{
                .nav-link{
                    height: auto;
                }
            }
        }
        .nav-item{
            
            &:not(:first-child) {
                margin-left: $spacer * .25;
            }
        }
    }
    header {
        .navbar-link-white {
            .navbar-brand {
                .navbar-brand-sticky {
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }
        &.scroll-up,
        &.is-sticky {
            .navbar-link-white {
                .navbar-brand {
                    .navbar-brand-sticky {
                        opacity: 1;
                        visibility: visible;
                    }
                    .navbar-brand-transparent {
                        opacity: 0;
                        visibility: hidden;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    header.sticky-reverse {
        .navbar-link-white {
            .navbar-brand {
                .navbar-brand-sticky {
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }
    }
    .navbar-expand-lg.navbar {
       
        .navbar-collapse {
            max-height: 85vh;
            overflow-y: auto;
            margin-top: $navbar-padding-y;
            border-bottom-left-radius: $border-radius-lg;
            border-bottom-right-radius: $border-radius-lg;
            > .navbar-nav {
                padding: 1rem;
            }
        }
        &.navbar-light {
            .navbar-collapse {
                background-color: $white;
                box-shadow: $box-shadow;
            }
        }
        &.navbar-dark {
            .navbar-collapse {
                background-color: $dark;
            }
        }
    }
}

.navbar-toggler {
    padding: 0;
    width: 40px;
    height: 40px;
    border-radius: $border-radius;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    border: none;
    background-color: $white;
    &:focus {
        outline: 0;
        box-shadow: none;
    }
    .navbar-toggler-icon {
        background-image: none;
        width: 24px;
        height: 24px;
        position: relative;
        display: inline-block;
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease;
        vertical-align: middle;
        font-size: 0;
        cursor: pointer;
        > i {
            width: 100%;
            height: 2px;
            display: block;
            position: absolute;
            top: 50%;
            margin-top: -1px;
            background-color: $primary;
            -webkit-transition: background-color 0s 0.3s;
            transition: background-color 0s 0.3s;
            &::before,
            &::after {
                content: "";
                width: 100%;
                height: 2px;
                position: absolute;
                display: block;
                left: 0;
                background-color: $primary;
            }
            &::before {
                top: 8px;
                transition: top 0.3s 0.3s, transform 0.3s 0s, background-color 0.3s 0s, -webkit-transform 0.3s 0s;
            }
            &::after {
                bottom: 8px;
                transition: bottom 0.3s 0.3s, transform 0.3s 0s, background-color 0.3s 0s, -webkit-transform 0.3s 0s;
            }
        }
    }
    &[aria-expanded="true"] {
        .navbar-toggler-icon {
            > i {
                background-color: transparent;
                transition: background-color 0s 0.3s;
                &:before {
                    top: 0;
                    transform: rotate(45deg);
                    transition: top 0.3s 0s, transform 0.3s 0.3s, background-color 0.3s 0s, -webkit-transform 0.3s 0.3s;
                }
                &::after {
                    bottom: 0;
                    transition: bottom 0.3s 0s, transform 0.3s 0.3s, background-color 0.3s 0s,
                        -webkit-transform 0.3s 0.3s;
                    transform: rotate(-45deg);
                }
            }
        }
    }
}
