//Spinner style

//preloader spinner
.spinner-loader{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999999;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
body.loaded{
    .spinner-loader{
        transform: translateY(-100vh);
    transition: .7s cubic-bezier(.785,.135,.15,.86) .6s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    }
}